import React, { useState } from 'react'
import { Modal } from 'react-components/atoms'
import LazyLoad from 'react-lazyload'

const WImage = (props) => {
  const {
    src = '',
    alt = '',
    title = '',
    classPrefix = '',
    maxWidth = 0,
    maxHeight = 0,
    className = '',
    lazyLoad = false,
    pagebuilder = false,
    zoommabile = false,
    is_link = false,
  } = props

  const [open, setOpen] = useState(false)

  const imageUrl =
    process.env.NODE_ENV === 'production' || pagebuilder || src?.indexOf('http') === 0
      ? src
      : src
      ? src?.indexOf('/images/') === 0
        ? `http://localhost:3000${src}`
        : `http://localhost:9999${src}`
      : null

  const content = (
    <img
      src={imageUrl || `https://via.placeholder.com/${maxWidth}x${maxHeight}`}
      alt={alt}
      title={title}
      className={`${classPrefix ? `${classPrefix}__image ` : ''}${className}`}
      style={{ cursor: zoommabile || is_link ? 'pointer' : 'default' }}
      onClick={() => {
        if (zoommabile) {
          document.body.classList.add('w-scrolldown')
          setOpen(true)
        }
      }}
      loading="lazy"
    />
  )

  const modal = pagebuilder && zoommabile && (
    <Modal
      open={open}
      setOpen={() => {
        document.body.classList.remove('w-scrolldown')
        setOpen(false)
      }}
      closeButton={true}
      className="wmodal--lightbox"
    >
      {content}
    </Modal>
  )

  return lazyLoad ? (
    <LazyLoad height={maxHeight || 300} offset={100} once>
      {content}
      {modal}
    </LazyLoad>
  ) : (
    <>
      {content}
      {modal}
    </>
  )
}

export default WImage
